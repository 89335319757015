<template>
  <div class="animated fadeIn">
    <b-button variant="success" size="lg" @click="showModalCreate()">{{
      $t("message.newSim")
    }}</b-button>
    <b-card style="top: 10px">
      <b-form @submit.prevent="search">
        <div class="row">
          <div class="col-sm-6 col-md-6 col-lg-6 col-xl-2 my-1">
            {{ $t("message.refNumber") }} : <br />
            <b-form-input v-model="searchData.number" />
          </div>
          <div class="col-sm-6 col-md-6 col-lg-3 col-xl-2 my-1">
            {{ $t("message.simSerial") }} : <br />
            <b-form-input v-model="searchData.serial" />
          </div>
          <div class="col-sm-6 col-md-6 col-lg-3 col-xl-2 my-1">
            {{ $t("message.simProvider") }} : <br />
            <b-form-input v-model="searchData.provider" />
          </div>
          <div class="col-sm-6 col-md-6 col-lg-6 col-xl-2 my-1">
            {{ $t("message.note") }} : <br />
            <b-form-input v-model="searchData.note" />
          </div>
          <div class="col-sm-6 col-md-6 col-lg-3 col-xl-2 my-1">
            <br />
            <b-button
              variant="primary"
              :active="true"
              type="submit"
              @click="getSimList()"
              ><i class="fa fa-search"></i>&nbsp;{{
                $t("message.search")
              }}</b-button
            >
          </div>
        </div>
      </b-form>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <div class="table-responsive">
        <table class="table2 table-hover">
          <thead class="bg-primary" style="text-align-last: center">
            <th>{{ $t("message.refNumber") }}</th>
            <th>{{ $t("message.simSerial") }}</th>
            <th>{{ $t("message.note") }}</th>
            <th>{{ $t("message.simProvider") }}</th>
            <th>{{ $t("message.status") }}</th>
            <th>{{ $t("message.createdAt") }}</th>
            <th>{{ $t("message.updatedAt") }}</th>
            <th></th>
          </thead>
          <tbody style="text-align: center">
            <template v-for="items in rowData">
              <tr>
                <td>{{ items.number }}</td>
                <td style="text-align: center">{{ items.serial }}</td>
                <td style="text-align: center">{{ items.note }}</td>
                <td style="text-align: center" >
                  <nav style="width: 65px;">{{ items.provider }}</nav>
                </td>
                <template v-if="items.status === 'ON'">
                  <td style="text-align: center">
                    <span class="badge badge-success" style="font-size: 100%"
                      >ON</span
                    >
                  </td>
                </template>
                <template v-else>
                  <td style="text-align: center">
                    <span class="badge badge-danger" style="font-size: 100%"
                      >OFF</span
                    >
                  </td>
                </template>
                <td style="text-align: center"><label style="width: 170px;">{{ items.createdAt | moment("YYYY-MM-DD HH:mm") }}</label></td>
                <td style="text-align: center"><label style="width: 170px;">  {{ items.updatedAt | moment("YYYY-MM-DD HH:mm") }}</label></td>
                <td>
                  <b-button class="ml-1 mr-1 my-1" size="sm" variant="btn btn-warning" style="width: 50px;" @click="editData(items)" >{{ $t("message.edit") }}</b-button>
                  <b-button class="ml-1 mr-1 " size="sm" variant="btn btn-danger" style="width: 50px; background-color: red;" disabled>{{ $t("message.delete") }}</b-button>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <br />

      <b-pagination
        size="md"
        :total-rows="totalRows"
        v-model="pagination"
        :per-page="itemperPage"
        @input="pageChanged(pagination)"
      ></b-pagination>

      <!--      <uib-pagination :total-items="totalRows" :items-per-page="20" v-model="pagination" class="pagination-sm" @change="pageChanged(pagination.currentPage)"></uib-pagination>-->
    </b-card>

    <b-modal ref="createModal" @ok="createSim()" title="สร้างซิมใหม่">
      <div class="d-block">
        <table class="table2">
          <tr>
            <td>{{ $t("message.refNumber") }}</td>
            <td>
              <input
                class="form-control"
                type="number"
                v-model="ModalData.number"
              />
            </td>
          </tr>
          <tr>
            <td>{{ $t("message.simSerial") }}</td>
            <td><input class="form-control" v-model="ModalData.serial" /></td>
          </tr>
          <tr>
            <td>{{ $t("message.simProvider") }}</td>
            <td>
              <b-form-select
                v-model="ModalData.provider"
                :options="provider_edit"
              />
            </td>
          </tr>
          <tr>
            <td>{{ $t("message.status") }}</td>
            <td>
              <c-switch
                v-model="ModalData.status"
                variant="pill"
                label
                color="success"
                size="lg"
              />
            </td>
          </tr>
          <tr>
            <td>{{ $t("message.note") }}</td>
            <td><input class="form-control" v-model="ModalData.note" /></td>
          </tr>
        </table>
      </div>
    </b-modal>

    <b-modal
      ref="editModal"
      @ok="saveDataEdit()"
      :title="$t('message.editSim')"
    >
      <div class="d-block">
        <table class="table2">
          <tr>
            <td>{{ $t("message.refNumber") }}</td>
            <td>
              <input class="form-control" v-model="ModalDataEdit.number" />
            </td>
          </tr>
          <tr>
            <td>{{ $t("message.simSerial") }}</td>
            <td>
              <input class="form-control" v-model="ModalDataEdit.serial" />
            </td>
          </tr>
          <tr>
            <td>{{ $t("message.simProvider") }}</td>
            <td>
              <b-form-select
                v-model="ModalDataEdit.provider"
                :options="provider_edit"
              />
            </td>
          </tr>
          <tr>
            <td>{{ $t("message.status") }}</td>
            <td>
              <c-switch
                v-model="ModalDataEdit.status"
                variant="pill"
                label
                color="success"
                size="lg"
                value="ON"
                uncheckedValue="OFF"
              />
            </td>
          </tr>
          <tr>
            <td>{{ $t("message.note") }}</td>
            <td><input class="form-control" v-model="ModalDataEdit.note" /></td>
          </tr>
        </table>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { Switch as cSwitch } from "@coreui/vue";
import webServices from "../../script";
export default {
  name: "SimManager",
  components: {
    cSwitch,
  },
  data() {
    return {
      newSIM: {},
      ModalData: {},
      ModalDataEdit: {},
      pagination: 1,
      totalRows: 1,
      itemperPage: 20,
      searchData: {},
      provider_edit: [
        { value: "TRUE", text: "TRUE" },
        { value: "AIS", text: "AIS" },
        { value: "DTAC", text: "DTAC" },
        { value: "CAT", text: "CAT" },
        { value: "TOT", text: "TOT" },
      ],
      provider_select: [
        { value: null, text: "All" },
        { value: "TRUE", text: "TRUE" },
        { value: "AIS", text: "AIS" },
        { value: "DTAC", text: "DTAC" },
        { value: "CAT", text: "CAT" },
        { value: "TOT", text: "TOT" },
      ],
      status_select: [
        { value: null, text: "All" },
        { value: "ON", text: "ON" },
        { value: "OFF", text: "OFF" },
      ],
      rowData: [],
    };
  },
  methods: {
    getSimList() {
      this.$Progress.start();
      webServices
        .getSimList(this.searchData)
        .then((res) => {
          this.$Progress.finish();
          this.rowData = res.data;
          console.log(res.data);
        })
        .catch((err) => {
          this.$Progress.fail();
          console.log(err);
          console.log("error @sim manager");
          this.$toast.error({
            title: "ERROR",
            message: err,
          });
        });
    },
    search() {
      console.log(this.searchData);
    },
    showModalCreate() {
      this.$refs.createModal.show();
    },
    editData(data) {
      this.ModalDataEdit = data;
      this.$refs.editModal.show();
    },
    createSim() {
      // console.log(this.ModalData)
      if (this.ModalData.status) {
        this.ModalData.status = "ON";
      } else {
        this.ModalData.status = "OFF";
      }
      webServices
        .createSim(this.ModalData)
        .then((res) => {
          console.log(res.data);
          if (res.data.success) {
            this.$toast.success({
              title: "SUCCESS",
              message: "สร้างซิมสำเร็จ",
            });
          } else {
            this.$toast.error({
              title: "ERROR",
              message: "ไม่สามารถสร้างซิมได้",
            });
          }
          this.getSimList();
        })
        .catch((err) => {
          const errorText = {
            error: err.response.data,
            code: err.response.status,
            text: err.response.statusText,
          };
          this.$toast.error({
            title: "ERROR",
            message: JSON.stringify(errorText),
          });
          console.log(err);
        });
    },
    saveDataEdit() {
      // console.log(this.ModalDataEdit)
      delete this.ModalDataEdit.createdAt;
      delete this.ModalDataEdit.updatedAt;
      webServices
        .editSim(this.ModalDataEdit)
        .then((res) => {
          console.log(res.data);
          if (res.data.success) {
            this.$toast.success({
              title: "SUCCESS",
              message: "แก้ไขซิมสำเร็จ",
            });
          } else {
            this.$toast.error({
              title: "ERROR",
              message: "ไม่สามารถแก้ไขซิมได้",
            });
          }
          this.getSimList();
        })
        .catch((err) => {
          const errorText = {
            error: err.response.data,
            code: err.response.status,
            text: err.response.statusText,
          };
          if (err.response.status === 401) {
            webServices.tokenExpire();
          }
          this.$toast.error({
            title: "ERROR",
            message: JSON.stringify(errorText),
          });
          console.log(err);
        });
      this.ModalDataEdit = {};
    },
  },
  mounted() {
    this.getSimList();
  },
};
</script>
<style>
.table2 {
  width: 100%;
  border: none;
  border-collapse: collapse;
}
.table2 td,
.table2 th {
  padding: 8px;
}
</style>
